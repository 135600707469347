import type { BlockYouTubeVideo } from 'types/block-youtube-video';
import type { Media } from 'types/media';
import React, { memo, useRef, useState } from 'react';
import Image from 'next/image';
import { Wrap } from '@leafwell/components';
import Script from 'next/script';
import { YOUTUBE_IFRAME_SCRIPT_URL, getYoutubeImage } from '../constants';
import HeadingBody from './heading-body';

type ExtendedWindow = Window &
  typeof globalThis & {
    onYouTubeIframeAPIReady: () => void;
    YT: {
      Player(
        name: string,
        options: Record<string, string | number | object>,
      ): void;
    };
  };

const PLAY_STATUS = 1;
const VIDEO_IFRAME_ID = 'iframe-video';

const VideoContent: React.FC<{
  youtubeVideoId?: string;
  image?: Media;
  playButtonStyle?: string;
}> = ({ youtubeVideoId, image, playButtonStyle }) => {
  const [videoLoaded, setIsLoaded] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  const handlePlayActions = (stateValue?: boolean) => {
    setIsPlaying(stateValue);
  };

  return (
    <div className="relative overflow-hidden group w-full aspect-w-16 aspect-h-9 rounded-lg">
      <Image
        src={image?.src || getYoutubeImage(youtubeVideoId)}
        className="md:transform md:transition-transform md:duration-700 md:ease-out md:group-hover:scale-105"
        width={1920}
        height={1080}
        sizes="(max-width: 1023px) 66vw, 100vw"
        style={{
          width: '100%',
          height: 'auto',
        }}
        quality={50}
        alt="Youtube poster"
      />
      {videoLoaded && (
        <>
          <Script
            id="youtube-script"
            type="text/javascript"
            src={YOUTUBE_IFRAME_SCRIPT_URL}
            onReady={() => {
              const extendedWindow = window as ExtendedWindow;
              extendedWindow.onYouTubeIframeAPIReady = () => {
                videoRef.current = new extendedWindow.YT.Player(
                  VIDEO_IFRAME_ID,
                  {
                    videoId: youtubeVideoId,
                    playerVars: {
                      rel: '0',
                      height: '100%',
                      width: '100%',
                    },
                    events: {
                      onStateChange: () => {
                        handlePlayActions(
                          videoRef.current.getPlayerState() === PLAY_STATUS,
                        );
                      },
                      onReady: e => {
                        setIsLoaded(true);
                        e.target.playVideo();
                      },
                      onEnd: () => handlePlayActions(false),
                      onPause: () => handlePlayActions(false),
                      onPlay: () => handlePlayActions(true),
                    },
                  },
                );
              };
            }}
            async
          />
          <div className="absolute w-full h-full">
            <div
              className={[
                'transition-all duration-500 h-full',
                !isPlaying ? 'opacity-0 pointer-events-none' : '',
              ].join(' ')}
            >
              <div id={VIDEO_IFRAME_ID} className="w-full h-full"></div>
            </div>
          </div>
        </>
      )}
      <button
        className={[
          playButtonStyle === 'tertiary'
            ? 'absolute left-[5%] top-[80%] w-fit h-fit'
            : ' grid items-center justify-center',
          'md:transition-all md:hover:opacity-70',
          isPlaying ? 'opacity-0 invisible' : '',
        ].join(' ')}
        onClick={() => {
          videoLoaded ? videoRef?.current?.playVideo() : setIsLoaded(true);
        }}
      >
        {playButtonStyle === 'primary' ? (
          <Image
            src="/play-button.svg"
            width={106}
            height={86}
            alt="Play video"
          />
        ) : playButtonStyle === 'secondary' ? (
          <Image
            src="/play-button-secondary.svg"
            width={70}
            height={70}
            alt="Play video"
          />
        ) : (
          <Image
            src="/play-button-tertiary.svg"
            width={56}
            height={56}
            alt="Play video"
            className="w-6 h-6 lg:w-8 lg:h-8 xl:w-12 xl:h-12"
          />
        )}
      </button>
    </div>
  );
};

const VideoYoutube: React.FC<
  Omit<BlockYouTubeVideo, 'acfFcLayout'> & { removePadding?: boolean }
> = memo(
  ({
    sectionId,
    title,
    content,
    youtubeVideos,
    videoSize,
    removePadding = false,
  }) => {
    const hasVideos = youtubeVideos?.length > 0;
    const moreThanOne = youtubeVideos?.length > 1;
    const hasTitle = title?.length > 0;

    const getWrapStyles = () => {
      const styles = ['relative'];

      if (moreThanOne) {
        styles.push('md:grid-cols-2 gap-8');
      }

      if (!hasTitle && !removePadding) {
        styles.push('!py-2 md:!py-10 lg:!pt-5');
      }

      if (videoSize === 'small' && !removePadding && moreThanOne) {
        styles.push('lg:px-40');
      }

      if (videoSize === 'small' && !removePadding && !moreThanOne) {
        styles.push('lg:max-w-4xl');
      }

      if (videoSize !== 'small' && !removePadding) {
        styles.push('lg:!pb-20');
      }

      if (removePadding) {
        styles.push('!p-0');
      }

      return styles.join(' ');
    };

    return hasVideos ? (
      <Wrap id={sectionId} className={getWrapStyles()}>
        {title?.length > 0 || content?.length > 0 ? (
          <div className="col-span-full text-center">
            {title?.length > 0 && (
              <HeadingBody className="!mb-2" {...{ level: '2', title }} />
            )}
            {content?.length > 0 ? <p>{content}</p> : null}
          </div>
        ) : null}
        {youtubeVideos?.map(({ youtubeVideoId, image, playButtonStyle }) => (
          <VideoContent
            key={youtubeVideoId}
            youtubeVideoId={youtubeVideoId}
            image={image}
            playButtonStyle={playButtonStyle}
          />
        ))}
      </Wrap>
    ) : null;
  },
);

export default VideoYoutube;
